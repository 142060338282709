<template>
  <div>
    <h2>{{ category.name }}</h2>
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <h3>Posts</h3>
            <table class="table table-responsive border-bottom">
              <tbody>
                <tr v-for="(topic, i) in category.posts" :key="`discussion-${i}`">
                  <td width="15%">
                    <router-link
                      :to="{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <img
                        :src="topic.author.avatar"
                        :alt="topic.title"
                        :class="`avatar ${loading ? 'skeleton' : ''}`"
                      />
                    </router-link>
                  </td>
                  <td class="border-right">
                    <router-link
                      :to="{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <h4
                        :class="
                          `mb-0 ${loading ? 'skeleton skeleton-text' : ''}`
                        "
                      >
                        {{ topic.title }}
                      </h4>
                      <p :class="loading ? 'skeleton skeleton-text' : ''">
                        <small>{{ topic.date }}</small>
                      </p>
                    </router-link>
                  </td>
                  <td width="10%">
                    <router-link
                      :to="{
                        name: 'moderator.discussions.show',
                        params: { id: topic.id },
                      }"
                    >
                      <p class="mb-0 text-black">Replies</p>
                      <p :class="loading ? 'skeleton skeleton-text' : ''">
                        {{ topic.comments }}
                      </p>
                    </router-link>
                  </td>
                </tr>
                <tr v-if="category.posts.length == 0">
                  <td>
                    <div class="alert alert-warning">
                      There is no data to display
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="alert alert-warning">Deleting this category will also delete all posts</div>
        <a href="#" class="btn btn-danger btn-block px-3 py-2" @click.prevent="remove"><i class="lni lni-trash mr-2"></i>Delete</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      category: {posts: [{author: {}}, {author: {}}]},
      loading: true
    };
  },

  methods: {
    fetch() {
      this.$axios
        .get(`/api/v1/moderator/categories/${this.$route.params.id}`)
        .then((response) => {
          this.category = response.data.category;
          this.loading = false
        });
    },

    remove() {
      this.$axios.delete(`/api/v1/moderator/categories/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: 'moderator.categories.index' })
      })
    }
  },
};
</script>
