var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.category.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h3',[_vm._v("Posts")]),_c('table',{staticClass:"table table-responsive border-bottom"},[_c('tbody',[_vm._l((_vm.category.posts),function(topic,i){return _c('tr',{key:("discussion-" + i)},[_c('td',{attrs:{"width":"15%"}},[_c('router-link',{attrs:{"to":{
                      name: 'moderator.discussions.show',
                      params: { id: topic.id },
                    }}},[_c('img',{class:("avatar " + (_vm.loading ? 'skeleton' : '')),attrs:{"src":topic.author.avatar,"alt":topic.title}})])],1),_c('td',{staticClass:"border-right"},[_c('router-link',{attrs:{"to":{
                      name: 'moderator.discussions.show',
                      params: { id: topic.id },
                    }}},[_c('h4',{class:("mb-0 " + (_vm.loading ? 'skeleton skeleton-text' : ''))},[_vm._v(" "+_vm._s(topic.title)+" ")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : ''},[_c('small',[_vm._v(_vm._s(topic.date))])])])],1),_c('td',{attrs:{"width":"10%"}},[_c('router-link',{attrs:{"to":{
                      name: 'moderator.discussions.show',
                      params: { id: topic.id },
                    }}},[_c('p',{staticClass:"mb-0 text-black"},[_vm._v("Replies")]),_c('p',{class:_vm.loading ? 'skeleton skeleton-text' : ''},[_vm._v(" "+_vm._s(topic.comments)+" ")])])],1)])}),(_vm.category.posts.length == 0)?_c('tr',[_vm._m(0)]):_vm._e()],2)])])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v("Deleting this category will also delete all posts")]),_c('a',{staticClass:"btn btn-danger btn-block px-3 py-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)}}},[_c('i',{staticClass:"lni lni-trash mr-2"}),_vm._v("Delete")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" There is no data to display ")])])}]

export { render, staticRenderFns }